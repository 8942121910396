export const TranslateUz = {
  account: "Hisob",
  action: "Harakat",
  active: "Faolligi",
  allNotification: "Barcha bildirishnomalar",
  amount: "Miqdori",
  cancel: "Bekor qilish",
  cancelModal: "Haqiqatan ham toʻlovni bekor qilmoqchimisiz?",
  chequeAmount: " Chek miqdori",
  close: "Yopish",
  continue: "Davom etish",
  createPayment: "To'lovni yaratish",
  createTerminal: "Terminal yaratish",
  editTerminal: "Terminalni taxrirlash",
  tableTr: "T/r",
  dashboard: "Boshqaruv paneli",
  date: "Sana",
  deleteNotification: "Xabarni o'chirish",
  email: "Email",
  enterTheAmount: "Miqdorni kiriting",
  enterTheTermenalAccount: "Terminal hisobini kiriting",
  enterTheTermenalFilialCode: "Terminal filial kodini kiriting",
  enterTheTermenalInn: "Terminal inn raqamini kiriting",
  enterTheTermenalName: "Terminal nomini kiriting",
  enterTheTermenalPassword: "Terminal parolini kiriting",
  enterTheTermenalPhoneNumber: "Terminal telefon raqamini kiriting",
  enterYourEmail: "Emailingizni kiriting",
  enterYourFilialCode: "Filial kodingizni kiriting",
  enterYourFirstName: "Ismingizni kiriting",
  enterYourInn: "Inn raqamingizni kiriting",
  enterYourLastName: "Familiyangizni kiriting",
  enterYourPassword: "Parolingizni kiriting",
  enterYourPhoneNumber: "Telefon raqamingizni kiriting",
  error: " talab qilinadi.",
  extId: "Ext id",
  filial_code: "Filial kodi",
  firstName: "Ism",
  fullName: "Ism familiya",
  img: "https://cdn-icons-png.flaticon.com/512/6211/6211657.png",
  inn: "Inn raqami",
  lang: "UZ",
  lastName: "Familiya",
  localQRId: "Loacal QR",
  logOut: "Chiqish",
  merchent: "Savdogar",
  name: "Ism",
  notFound: " topilmadi",
  notificationDelete: "Barcha bildirishnomalarni o'chirib tashlaysizmi?",
  partner: "Hamkor",
  password: "Parol",
  loading: "yuklanmoqda",
  passwordError: "Parol kamida 4 ta belgidan iborat bo'lishi kerak.",
  passwordErrorHolder: "Min. 5 ta belgi",
  passwordWarn:
    "Agar eski parolni saqlab qolmoqchi bo'lsangiz hech qanday malumot kiritmang!",
  payment: "To'lov",
  phone: "Telefon raqam",
  phoneError:
    "Telefon raqami aniq 9 ta belgidan iborat bo'lishi va faqat raqamlardan iborat bo'lishi kerak.",
  phones: "Telefon raqamlar",
  purpose: "Maqsad",
  QRAmount: "QR miqdori",
  QRId: "QR id",
  rate: "Daraja",
  refund: "To'lovni bekor qilish",
  request: "So'rovlar",
  save: "Saqlash",
  signIn: "Tizimga kirish",
  signUp: "Ro'yhatdan o'tish",
  status: "Status",
  terminal: "Terminal",
  terminalName: "Terminal nomi",
  terminalUsers: "Terminal foydalanuvchilari",
  update: "Tahrirlar",
  updateProfile: "Hisobni yangilash",
  viewMore: "Koʻproq koʻrish",
  hey: "Salom",
  wait: "Kutilayotgan",
  canceled: "Bekor qilingan",
  confirmed: "Tasdiqlangan",
  table: "jadvali",
  terminalSerialCode: "Terminalning seriya kodi",
  optional: "ixtiyory",
  merchant: "Savdogar",
  search_by: "bo'yicha qidirish",
  notification: "Bildirishnoma",

  leaveRequest: "So'rov qoldiring",
  adminVerifed:
    "Maʼlumotlaringiz tekshirilgandan soʻng admin siz bilan bogʻlanadi.",
  enterFullName: "Toʻliq ismingizni kiriting",
  logIn: "Tizimga kirish",
  iHaveAccount: "Menda allaqachon hisob bor.",
  send: "Yuborish",
  checkData: "Ma'lumotlarning to'g'riligini tekshiring",
  notRegistered: "Hali ro'yxatdan o'tmaganmisiz?",
  leaveRequestFor: "Ro'yxatdan o'tish uchun ariza qoldiring.",

  terminals: "Terminallar",
  terminalUserCount: "Terminal foydalanuvchilari soni",
  transactions: "Tranzaktsiyalar",
  totalBalance: "Umumiy balans",
  canceledTransactions: "Bekor qilingan tranzaktsiyalar",

  monthlyStatistic: "Oylik statistika",
  statistic: "Statistika",
  requestOnWait: "Kutilayotgan so'rovlar",

  Active: "Faol",
  inActive: "Faol emas",
  selectActive: "Holatni tanlang",

  delete: "O'chirish",
  createUser: "Foydalanuvchi qo'shish",
  selectTerminal: "Terminalni tanlang",

  serialCode: "Serial code",
  signInDesc:
    "Kirish uchun telefon raqamingizni kiriting va sizga sms kod yuboriladi.",
  code: "Kod ",
  codeSend: " raqamiga yuborildi.",
  enterCode: "SMS codni kiriting",
  codeNotGet: "Kod kelmadimi?",
  resend: "Kodni qayta yuborish",

  codeResent: "Kod qayta yuborildi!",

  policyNotChecked: "Siz hali Foydalanish shartlari, Maxfiylik siyosatiga rozilik bildirmagansiz.",
  enterBy: "Tizimga kirish orqali siz ",
  termsAndPolicy: "Foydalanish shartlari, Maxfiylik siyosatiga ",
  agree: "rozilik bildirasiz"
};
