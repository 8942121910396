export const TranslateRu = {
    account: "Счет",
    action: "Действие",
    active: "Активность",
    allNotification: "Все уведомления",
    amount: "Сумма",
    cancel: "Отмена",
    cancelModal: "Вы действительно хотите отменить платеж?",
    chequeAmount: "Сумма чека",
    close: "Закрыть",
    continue: "Продолжить",
    createPayment: "Создать платеж",
    createTerminal: "Создать терминал",
    editTerminal: "Редактирование терминала",
    tableTr: "Т/р",
    dashboard: "Панель управления",
    date: "Дата",
    deleteNotification: "Удалить уведомление",
    email: "Электронная почта",
    enterTheAmount: "Введите сумму",
    enterTheTermenalAccount: "Введите счет терминала",
    enterTheTermenalFilialCode: "Введите филиальный код терминала",
    enterTheTermenalInn: "Введите ИНН терминала",
    enterTheTermenalName: "Введите название терминала",
    enterTheTermenalPassword: "Введите пароль терминала",
    enterTheTermenalPhoneNumber: "Введите номер телефона терминала",
    enterYourEmail: "Введите вашу электронную почту",
    enterYourFilialCode: "Введите ваш филиальный код",
    enterYourFirstName: "Введите ваше имя",
    enterYourInn: "Введите ваш ИНН",
    enterYourLastName: "Введите вашу фамилию",
    enterYourPassword: "Введите ваш пароль",
    enterYourPhoneNumber: "Введите ваш номер телефона",
    error: " требуется",
    extId: "Ext id",
    filial_code: "Филиальный код",
    firstName: "Имя",
    fullName: "Имя фамилия",
    img: 'https://cdn-icons-png.flaticon.com/512/6211/6211549.png',
    inn: "ИНН",
    lang: "RU",
    lastName: "Фамилия",
    localQRId: "Локальный QR",
    logOut: "Выход",
    merchent: "Торговец",
    name: "Имя",
    notFound: " не найдено",
    notificationDelete: "Вы хотите удалить все уведомления?",
    partner: "Партнер",
    password: "Пароль",
    loading: "загрузка",
    passwordError: "Пароль должен содержать не менее 4 символов.",
    passwordErrorHolder: "Мин. 5 символов",
    passwordWarn: "Если вы хотите сохранить старый пароль, оставьте это поле пустым!",
    payment: "Платеж",
    phone: "Номер телефона",
    phoneError: "Номер телефона должен содержать ровно 9 символов и состоять только из цифр.",
    phones: "Номера телефонов",
    purpose: "Цель",
    QRAmount: "Сумма QR",
    QRId: "QR id",
    rate: "Оценка",
    refund: "Отмена платежа",
    request: "Запросы",
    save: "Сохранить",
    signIn: "Вход в систему",
    signUp: "Регистрация",
    status: "Статус",
    terminal: "Терминал",
    terminalName: "Имя терминала",
    terminalUsers: "Пользователи терминала",
    update: "Изменить",
    updateProfile: "Обновить аккаунт",
    viewMore: "Посмотреть больше",
    hey: "Привет",
    wait: "Ожидал",
    canceled: "Отменено",
    confirmed: "Подтвержденный",
    table: "расписание",
    terminalSerialCode: "Серийный код терминала",
    optional: "необязательный",
    merchant: "Торговец",
    search_by: "поиск по",
    notification: "Уведомление",

    leaveRequest: "Оставьте заявку",
    adminVerifed: "Администратор свяжется с вами после проверки ваших данных.",
    enterFullName: "Введите ваше полное имя",
    logIn: "Войти в систему",
    iHaveAccount: "У меня уже есть аккаунт.",
    send: "Отправить",
    checkData: "Проверьте правильность данных",
    notRegistered: "Вы еще не зарегистрированы?",
    leaveRequestFor: "Оставьте заявку на регистрацию.",

    terminals: "Терминалы",
    terminalUserCount: "Количество пользователей терминалов",
    transactions: "Транзакции",
    totalBalance: "Общий баланс",
    canceledTransactions: "Отмененные транзакции",

    monthlyStatistic: "Ежемесячная статистика",
    statistic: "Cтатистика",
    requestOnWait: "Запросы на ожидание",

    Active: "Активный",
    inActive: "Не активен",
    selectActive: "Выберите статус",

    serialCode: "Серийный код",
signInDesc: "Введите свой номер телефона для входа, и вам будет отправлен SMS-код.",
code: "Код",
codeSend: " отправлен на номер.",
enterCode: "Введите SMS-код",
codeNotGet: "Не получили код?",
resend: "Отправить код повторно",

codeResent: "Код отправлен повторно!",

policyNotChecked: "Вы еще не согласились с Условиями использования и Политикой конфиденциальности.",
enterBy: "Авторизуясь, вы соглашаетесь с ",
termsAndPolicy: "Условия использования, Политика конфиденциальности.",
agree: " "

};
